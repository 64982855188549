export const container = `
  width: 90%;
  max-width: 1200px;
  margin: 0px auto;
`;

export const simpleFlex = `
  display: flex;
  align-items: center;
`;
